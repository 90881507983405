import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutUs = () => {
  return (
    <Container className="mt-5">
      {/* Mission Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Our Mission</h2>
          <p className="poppins-regular">
            At TechConnect Foundation, we remove barriers to technology by providing refurbished laptops and online resources to individuals, families, and nonprofits in need. Our mission is to bridge the digital divide and promote equity, ensuring everyone has the opportunity to learn, grow, and improve their quality of life through accessible technology and education.
          </p>
        </Col>
      </Row>

      {/* History Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Our History</h2>
          <p className="poppins-regular">
            My sister developed biotechnology materials to teach students in rural India via Zoom, but she quickly realized many students lacked access to laptops or PCs to participate in online sessions. With just one laptop for 400 students, she had to coordinate with the school to set up workshops. This experience highlighted that for many, owning a laptop and having internet access is a luxury. Discovering that approximately 34 million people in the U.S. also lack computers, I felt compelled to take action and make computers accessible to all, aiming to improve quality of life through technology.
          </p>
        </Col>
      </Row>

      {/* Founder Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Founder: Sushant Medikondla</h2>
          <p className="poppins-regular">
            Sushant Medikondla is a high school junior from the northern suburbs of Chicago. In his spare time, he loves playing soccer, watching sports, teaching younger kids, reading, and discovering new things about geography. You can reach him at <a href="mailto:sushant@techconnectfoundation.com">sushant@techconnectfoundation.com</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
