import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Programs = () => {
  return (
    <Container className="mt-5">
      {/* Computer Distribution Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Computer Distribution</h2>
          <p className="poppins-regular">
            Our Computer Distribution program takes donated computers and gives them a second life! We refurbish them and provide them to people and communities who really need them. This way, more people get access to technology for learning, working, or just staying connected with the world.
          </p>
        </Col>
      </Row>

      {/* Online Courses Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Online Courses</h2>
          <p className="poppins-regular">
            We also offer online courses to help people improve their tech skills. Whether it's learning the basics or mastering advanced computer skills, our courses are open to everyone. We believe that education is the key to unlocking new opportunities, and these courses help people take their next steps.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Programs;
