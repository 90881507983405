import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './Home/MainPage/Main';
import AboutUs from './AboutUs/About';
import Programs from './Programs/Programs';
import SupportUs from './SupportUs/SupportUs';
import SucessStories from './SucessStories/SucessStories';
import ContactUs from './ContactUs/ContactUs';

function AppRouter() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/programs" element={<Programs />} />
        <Route exact path="/supportus" element={<SupportUs />} />
        <Route exact path="/sucessstories" element={<SucessStories />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
      </Routes>
      </>
  );
}

export default AppRouter;
