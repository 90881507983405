import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SucessStories = () => {
  return (
    <Container className="mt-5">
      {/* Volunteer Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Collaborating to Bridge the Digital Divide</h2>
          <p className="poppins-regular">
            We are proud to collaborate with other non-profit organizations in Chicago that are working tirelessly to identify low-income individuals and families in need of computers. By joining forces, we will be able to reach those who need support the most. Additionally, we are in contact with Public Schools, which have identified students with a critical need for access to technology.
          </p>
          <p className="poppins-regular">
            While our journey has just begun, we have already donated a limited number of laptops to individuals in northern suburban communities. The heartfelt appreciation from those who have received these computers is truly priceless. These stories of gratitude motivate us to continue expanding our efforts and making a greater impact.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SucessStories;
