import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

function Main() {
  return (
    <>
      {/* First Section: Image on the Left, Text on the Right */}
      <div style={{ backgroundColor: '#33acff', padding: '50px 0' }}>
        <Row className="mb-5">
          {/* Left Column: Image */}
          <Col md={4} className="d-flex justify-content-center align-items-center">
            <img
              src="images/tcf_2.png"
              alt="TechConnect Foundation"
              style={{ maxWidth: '400px', height: 'auto', padding: '10px' }}
            />
          </Col>

          {/* Right Column: Featured Content */}
          <Col md={6} className="d-flex flex-column justify-content-center">
            <h1 className="poppins-bold">Making Computer Ownership Possible For Everyone</h1>
            <p className="poppins-regular">
              TechConnect is committed to providing technology and educational resources to underserved communities in Illinois. Join us in closing the digital divide.
            </p>
          </Col>
        </Row>
      </div>

      {/* Second Section: Underrepresented Households Statistics */}
      <div style={{ backgroundColor: '#61d0e8', padding: '50px 0' }}>
        <Row className="mb-5">
          <Col md={12} className="text-center">
            <h2 className="poppins-bold">The Digital Divide</h2>
            <p className="poppins-regular">
              1 in 3 underrepresented households lack access to a computer. Device ownership is crucial for digital equity and access to opportunities, yet significant technology gaps persist nationwide.
            </p>
          </Col>
        </Row>
      </div>

      {/* Third Section: Digital Literacy Statistics */}
      <div style={{ backgroundColor: '#dee2e6', padding: '50px 0' }}>
        <Row className="mb-5">
          <Col md={12} className="text-center">
            <h2 className="poppins-bold">Digital Literacy Matters</h2>
            <p className="poppins-regular">
              52 million adults in the U.S. are not digitally literate. This lack of skills hinders education, employment, and quality of life for millions.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Main;