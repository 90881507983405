import React, { useState } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import './ContactUs.css';
import $ from 'jquery';

function ContactUs() {
    const apiEndpoint = process.env.REACT_APP_API_EMAIL_INVOKE_URL;
    const apiKey = process.env.REACT_APP_API_EMAIL_KEY;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [validateForm, setValidateForm] = useState(false);
    const [newErrors, setNewErrors] = useState({});
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    function findFormErrors() {
        const newErrors = {};
        const emailSignin = $('#formEmail').val();
        if (!emailPattern.test(emailSignin)) {
            newErrors.email = 'Please enter a valid email address.';
        }
        return newErrors;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        const newErrors = findFormErrors();
        if (Object.keys(newErrors).length > 0) {
            setNewErrors(newErrors);
        }

        setValidateForm(true);
        if (!formData.email && !formData.message && !formData.name && !formData.subject) {
            return;
        }
        const requestData = {
            body: {
                ...formData
            }
        };

        const headers = {
            Accept: 'application/json',
            'x-api-key': apiKey
        };

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(requestData),
                contentType: 'application/json'
            });

            if (response.ok) {
                const data = await response.json();
                const message = data.customMessage.result[0].message;
                alert(message);
                window.location.href = '/';
            } else {
                console.error('API error:', response);
                alert('Error sending message. Please try again later.');
            }
        } catch (error) {
            console.error('API error:', error);
            alert('Error sending message. Please try again later.');
        }
    };

    return (
        <Container className="Auth-form-container d-flex align-items-center justify-content-center">
            <Row>
                <Col md={12} xs={12} lg={12} sm={12}>
                    <Form noValidate validated={validateForm} id="contactForm" className="Auth-form" onSubmit={handleSubmit}>
                        <div className='Auth-form-content'>
                            <div className='Auth-form-title'>
                                <h4 className="poppins-bold">Contact Us</h4>
                            </div>
                            <Form.Group controlId="formName">
                                <Row className="align-items-center mb-2"> {/* Align items center */}
                                    <Col md={4} xs={4} className="text-end"> {/* Align label to the end */}
                                        <Form.Label className="poppins-regular">Name:</Form.Label>
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                            style={{ marginBottom: '0' }} // Remove margin at the bottom
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <span>Please enter a valid name.</span>
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Row className="align-items-center mb-2">
                                    <Col md={4} xs={4} className="text-end">
                                        <Form.Label className="poppins-regular">Email:</Form.Label>
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                            style={{ marginBottom: '0' }} // Remove margin at the bottom
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {newErrors.email && <span>{newErrors.email}</span>}
                                            {!newErrors.email && <span>Please enter a valid email address.</span>}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="formSubject">
                                <Row className="align-items-center mb-2">
                                    <Col md={4} xs={4} className="text-end">
                                        <Form.Label className="poppins-regular">Subject:</Form.Label>
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleInputChange}
                                            required
                                            style={{ marginBottom: '0' }} // Remove margin at the bottom
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <span>Please enter a valid subject.</span>
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="formMessage">
                                <Row className="align-items-center mb-2">
                                    <Col md={4} xs={4} className="text-end">
                                        <Form.Label className="poppins-regular">Message:</Form.Label>
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <Form.Control
                                            as="textarea"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            required
                                            style={{ marginBottom: '0' }} // Remove margin at the bottom
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <span>Please enter a valid message.</span>
                                        </Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <div className="d-grid gap-2 mt-3">
                                <Button className="btn btn-navy mx-auto" type="submit">Submit</Button>
                            </div>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactUs;
